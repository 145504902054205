import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Mike Summerfeldt | Fullstack Developer', 
  lang: 'en', 
  description: ' ', 
};

// HERO DATA
export const heroData = {
  title: 'My name is,',
  name: 'Mike Summerfeldt',
  subtitle: 'I\'m a Fullstack Developer, and a fan of Open Source.',
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  img: 'mike.jpeg',
  paragraphOne: 'I\'m from a small town in Ontario, Canada. I have always had a natural curiosity and interest in tinkering, whether that be in a digital or physical space.',
  paragraphTwo: 'I currently work full time as a Fullstack Developer/Company Wide Technology Manager. In my free time I have begun to dabble more and more into Open Source contributions.',
  paragraphThree: 'I have always been a big supporter and advocate towards open-source technology even before dipping my toes into the waters of contributing. However now that I\'m in, the water is great and I don\'t want to get out.',
  resume: '', 
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'capElectron.png',
    title: 'Capacitor Community - Electron',
    info: 'Support for the electron platform on Capacitor from the Ionic Team.',
    info2: 'Now you can develop, PWA\'s, iOS, Android, and Desktop apps from one code base!',
    url: '',
    repo: 'https://github.com/capacitor-community/electron', 
  }
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'twitter',
      url: 'https://twitter.com/IT_MikeS',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/IT-MikeS',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, 
};
